<template>
  <g>
    <svg:style>
      .cls-3, .cls-4, {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }

    </svg:style>
    <linearGradient id="inox-gradient"
                    x1="69.9"
                    :y1="doorTopHeight"
                    x2="69.9"
                    :y2="doorTopHeight + doorHeight"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient" x1="69.9" y1="162.7" x2="69.9" y2="60.82" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="handle-gradient" x1="11.15" y1="148.47" x2="16.73" y2="148.47" xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V22">
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-3"
            :x="`${doorLeftWidth1 + 55.94}`"
            :y="`${doorTopHeight + inoxOffset}`"
            width="27.92"
            :height="inoxHeight"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-4"
                :points="`${doorLeftWidth1 + 60.83} ${doorTopHeight + 60.82} ${doorLeftWidth1 + 60.83} ${doorTopHeight + 162.7} ${doorLeftWidth1 + 78.98} ${doorTopHeight + 162.7} ${doorLeftWidth1 + 78.98} ${doorTopHeight + 60.82} ${doorLeftWidth1 + 60.83} ${doorTopHeight + 60.82}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 55.93,
      inoxOffsetBottom: 21.9,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxHeight() {
      return this.showBg ?
        this.doorHeight - this.inoxOffset - this.inoxOffsetBottom :
        this.doorHeight - this.inoxOffset - this.leafOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
  },
}
</script>
